import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"

import { detectMobileAndTablet, isSSR } from "utils"
import { useOnScreen } from "hooks/"
import ContentWrapper from "styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 3rem 0;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: unset;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 0;
      padding-left: 0;
    }
    .section-title {
      color: ${({ theme }) => theme.colors.white};
      text-align: center;
      padding: 0 2rem;
      margin-top: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`

const StyledClientsWrapper =  styled.div`
  position: relative;
  padding: 2rem 0px 2.5rem;
  overflow: hidden;
`

const StyledClients = styled.div`
  display: flex;
  position: relative;
  -webkit-overflow-scrolling: touch;
  animation: marquee 6s linear infinite;
  width: 200%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: marquee 20s linear infinite;
  }
  .client {
    height: 4rem;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin: auto;
    padding: 1rem;
    .logo {
      max-width: 63px;
      width: 63px;
      margin-right: 0.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        max-width: 7rem;
        width: 6rem;
      }
    }
  }
`

const ClientsHome = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { shownItems, clients } = exports

  const [shownClients, setShownClients] = useState(shownItems)

  const ref = useRef()
  const onScreen = useOnScreen(ref)

  const iControls = useAnimation()
  const bControls = useAnimation()

  useEffect(() => {
    // If mobile or tablet, show all clients initially
    // Otherwise clients.mdx will determine how many clients are shown
    // (isSSR) is used to prevent error during gatsby build
    if (!isSSR && detectMobileAndTablet(window.innerWidth)) {
      setShownClients(clients.length)
    }
  }, [clients])

  useEffect(() => {
    const sequence = async () => {
      await iControls.start(i => ({
        opacity: 1,
        scaleY: 1,
        transition: { delay: i * 0.1 },
      }))
      await bControls.start({ opacity: 1, scaleY: 1 })
    }
    sequence()
  }, [onScreen, shownClients, iControls, bControls])

  return (
    <StyledSection id="clients">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <StyledClientsWrapper>
          <StyledClients itemCount={clients.length} ref={ref}>
            {clients.slice(0, shownClients).map(({ alt, logo }, key) => {
              const image = getImage(logo)
              return (
                <motion.div
                  className="client"
                  key={key}
                  custom={key}
                  initial={{ opacity: 0, scaleY: 0 }}
                  animate={iControls}
                >
                  <GatsbyImage className="logo" alt={alt} image={image} />
                </motion.div>
              )
            })}
            {clients.slice(0, shownClients).map(({ alt, logo }, key) => {
              const image = getImage(logo)
              return (
                <motion.div
                  className="client"
                  key={key}
                  custom={key}
                  initial={{ opacity: 0, scaleY: 0 }}
                  animate={iControls}
                >
                  <GatsbyImage className="logo" alt={alt} image={image} />
                </motion.div>
              )
            })}
          </StyledClients>
        </StyledClientsWrapper>
      </StyledContentWrapper>
    </StyledSection>
  )
}

ClientsHome.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          clients: PropTypes.array.isRequired,
          shownItems: PropTypes.number.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default ClientsHome